<template>
    <el-drawer
      :visible.sync="shouldShow"
      direction="rtl"
      @close="closeEvent"
      size="35%"
    >
      <template #title>
        <!-- <p> {{serviceDetails.service === 'meal'?'Food':formatText(serviceDetails.service)}} service</p><br/> -->
        <span class="text-grey-tertiary font-sm">{{formatDate(serviceDetails.start_date,'do m, y')}} - {{formatDate(serviceDetails.end_date,'do m, y')}}</span>
      </template>
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
              <div class="mt-40 mb-3">
         <eden-table-actions
        :title="`${employeeInformation.length} Employees`"
        @search="searchEmployees"
      ></eden-table-actions></div>
          <el-table class="font-sm" :data="employeeInformation">
            <el-table-column width="200">
            <template #header>
              <eden-table-column-header
                :label="'Name'"
                :property="'name'"
              />
            </template>
            <template v-slot="scope">
              <div
                class="text-primary text-cursor"
              >
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template #header>
              <eden-table-column-header
                :label="'Email'"
                :property="'email'"
              />
            </template>
            <template v-slot="scope">
              <div>
                {{ scope.row.email }}
              </div>
            </template>
          </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </template>
  
  <script>
  // import CustomerPauseLog from "@/components/Customers/Individual/Overview/Pauses/CustomerPauseLog";
  // import { formatPauseLogs } from "../Pauses/format-data";
  import EdenTableColumnHeader from "@/components/Global/EdenTableColumnHeader.vue";
  import EdenTableActions from "@/components/Global/EdenTableActions.vue";
  
  export default {
    name: "CustomerSubscriptionCycle",
    components: {
      // CustomerPauseLog,
      EdenTableColumnHeader,
      EdenTableActions
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      serviceDetails: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        employeeInformation:[
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
          {name:'Damola Adegoke',email:'adesuwa@buycoins.africa'},
        ]
      };
    },
    computed: {
      shouldShow: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit("update:show", value);
        },
      },
      // title() {
      //   const { start_date, end_date } = this.subscription;
      //   const cycle = this.formatSubscriptionDate(start_date, end_date);
      //   return `${cycle.start} - ${cycle.end}`;
      // },
      // outstandingAmount() {
      //   if (this.subscription && this.subscription.outstanding_amount) {
      //     return this.subscription.outstanding_amount;
      //   }
      //   return 0;
      // },
      // subscriptionAmount() {
      //   const plan = this.subscription && this.subscription.plan;
      //   if (plan) {
      //     return this.subscription.amount;
      //   }
      //   return 0;
      // },
      // subscriptionLogs() {
      //   const { pauses, outstanding_services } = this.subscription;
      //   if (pauses && outstanding_services) {
      //     return formatPauseLogs(
      //       pauses,
      //       this.sortArrayIntoObject(outstanding_services, "id"),
      //     );
      //   }
      //   return [];
      // },
    },
    methods: {
      closeEvent() {
        this.$emit("close");
        this.shouldShow = false;
      },
      searchEmployees(){
  
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .summary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    p {
      font-size: 0.875rem;
    }
  
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  
    &:last-child span {
      font-weight: 600;
      font-size: 1.125rem;
    }
  }
  </style>
  