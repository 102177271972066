import axios from "axios";

export default {
    create({id, payload}) {
        return axios.post(`crm/customer/business/${id}/subscriptions`, payload);
      },

      update({id, payload}) {
        return axios.patch(`crm/customer/business/subscriptions/${id}`, payload);
      },

      cycles({company_id, sub_id}) {
        return axios.get(`crm/customer/business/${company_id}/subscriptions/${sub_id}/cycles`);
      },

      activate(id) {
        return axios.patch(`crm/customer/business/subscriptions/${id}/activate`);
      },

      details(id) {
        return axios.patch(`crm/customer/business/subscriptions/${id}`);
      },

      pay_subscription({company_id, sub_id, payload}){
        return axios.post(`crm/customer/business/${company_id}/subscriptions/${sub_id}/transactions`, payload)
      },

      merge_subscriptions(sub_id, start_date){
        return axios.patch(`crm/customer/business/subscriptions/${sub_id}/merge`, {start_date});
      },
}