<template>
  <div>
    <eden-page-header title="Customer" :subtitle="headerTitles?.subTitle" :section="headerTitles?.section"
      subsection="View Subscription Cycle" />
    <div>
      <eden-table-actions :title="title" :show-search="false" />
      <template>
        <p v-if="fetching">Loading</p>
        <template v-else>
          <el-table :data="pageData" style="width: 100%">
            <el-table-column min-width="400">
              <template slot="header">
                <div class="table--header">
                  <span>Subscription Cycle</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="is-flex align-center">
                  <span class="subscription-start">
                    {{ formatDate(scope.row.start_date, "ddd do, m y") }}</span>
                  <i class="eden-icon-arrow-right subscription-divider"></i>
                  <span class="font-sm">
                    {{ formatDate(scope.row.end_date, "ddd do, m y") }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <div class="table--header">
                  <span> Services</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="is-flex is-align-center">
                  <el-tag v-for="(service, i) in Object.keys(scope.row.plan)" :key="i" :style="{
                    background: `${setServiceType(service)}`,
                  }">{{ formatText(service) }}</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="200">
              <template slot="header">
                <div class="table--header">
                  <span> Outstanding Amount</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">{{
                  formatPrice(scope.row.outstanding_amount)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header">
                  Amount Charged
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">{{ formatPrice(scope.row.amount) }}</span>
              </template>
            </el-table-column>

            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header">
                  Employees Served
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <el-button class="font-lg text-underline" type="text" @click="getSubscriptionDetails"><strong>{{
                  scope.row.employees_count }}</strong> </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
    </div>
    <service-details :show="openDrawer" :serviceDetails="serviceDetails" @close="openDrawer = false"></service-details>
  </div>
</template>
  
<script>
import subscriptionRequest from "@/requests/business/subscription";
import ServiceDetails from "@/components/Business/Subscription/ServiceDetails.vue";
export default {
  name: "CustomerCorporateSubscriptionsCycles",
  components: {
    ServiceDetails
  },
  data() {
    return {
      openDrawer: false,
      customerName: "",
      fetching: false,
      page: 1,
      pageData: [],
      serviceDetails:{}
    };
  },
  computed: {
    title() {
      return `${this.pageData.length || 0} Subscription Cycles`;
    },
    customerId() {
      return this.$route.params.id;
    },

    headerTitles() {
    return {
      subTitle: this.$route.query.company_name,
      section: this.$route.query.subscription
    }
  },
  },


  created() {
    this.getCustomerSubscriptionCycles();
  },
  methods: {
    getCustomerSubscriptionCycles() {
      this.fetching = true;
      subscriptionRequest
        .cycles({
          company_id: this.$route.params.id,
          sub_id: this.$route.query.id
        })
        .then((response) => {
          if (response.data.status) {
            this.pageData = response.data?.data;
            this.customerName = response.data.data[0].name;
            this.fetching = false;
          }
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    getCustomer() {
      this.fetching = true;
      subscriptionRequest
        .get(this.customerId)
        .then((response) => {
          this.pageData = [response.data?.data];
          this.customerName = response.data.data.company_name;
          this.fetching = false;
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    getSubscriptionDetails() {
      this.openDrawer = true
      subscriptionRequest
        .details(this.customerId)
        .then((response) => {
          this.serviceDetails = response.data.data
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
  
<style scoped lang="scss">
.subscription-cycle {
  display: flex;
  align-items: center;

  span {
    min-width: 140px;
  }

  i {
    margin: 0 15px;
  }
}
</style>